import React from 'react'
import { parse } from 'qs'
import Copy from '@/components/Copy/'
import { Button } from 'antd'

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const bgImg = [
  require('@/static/0.jpg'),
  require('@/static/1.jpg'),
  require('@/static/2.jpg'),
  require('@/static/3.jpg'),
  require('@/static/4.jpg'),
  require('@/static/5.jpg')
]

function Bank() {
  const { href = '' } = window.location
  const [, search = ''] = href.split('?')
  const { bankAccount = '', cardNo = '', memo: bankName = '', p, p1 } =
    parse(search) || {}

  return (
    <div className="container order">
      <div className="order-info">
        <div className="order-info-content">
          <div className="order-info-content-title">银行卡信息</div>
          <div className="order-divider" />
          <div className="order-info-content-qr">
            <div style={{ minHeight: 36 }}>
              <div>
                <span>支付金额: </span> <span className="price">￥{p1}</span>
              </div>
              <div>
                <span style={{ paddingLeft: '1em' }}>充值金额: </span>{' '}
                <span>￥{p}</span>
              </div>
            </div>
            <div
              className="bank-card"
              style={{
                backgroundImage: `url(${
                  bgImg[randomIntFromInterval(0, bgImg.length - 1)]
                })`
              }}
            >
              <div>
                <h2 style={{ color: '#fff' }} className="bank-card-bank">
                  <Copy name="银行名" noBtn>
                    {bankName}
                  </Copy>
                </h2>
                <h3
                  style={{ marginTop: '3.5em', color: '#fff' }}
                  className="bank-card-account"
                >
                  <Copy name="户名" noBtn>
                    {bankAccount}
                  </Copy>
                </h3>
                <div
                  className="bank-card-no"
                  style={{ fontSize: 20, fontWeight: 600, color: '#fff' }}
                >
                  <Copy name="卡号" noBtn value={cardNo}>
                    {String(cardNo || '').replace(
                      /^(\d{4})\d+(\d{4})$/,
                      '$1 **** $2'
                    )}
                  </Copy>
                </div>
                <span
                  style={{
                    position: 'absolute',
                    right: 10,
                    bottom: 10
                  }}
                >
                  <svg
                    t="1576593168991"
                    className="icon"
                    viewBox="0 0 1325 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2271"
                    width="3em"
                    height="3em"
                  >
                    <path
                      d="M745.411765 90.352941v100.894118s79.811765-25.6 78.305882 66.258823l3.011765 243.952942-114.447059 66.258823 1.505882-161.129412c1.505882-51.2-102.4-51.2-102.4 0l3.011765 159.62353-114.447059-70.776471 3.011765-237.929412c-3.011765-91.858824 78.305882-66.258824 78.305882-66.258823V91.858824L745.411765 90.352941zM180.705882 789.082353l84.329412-49.694118s-63.247059-57.223529 18.070588-100.894117l207.811765-123.482353 112.941177 67.764706-138.541177 78.305882c-43.670588 24.094118 7.529412 115.952941 51.2 88.847059l135.529412-82.82353-3.011765 135.529412L442.729412 918.588235c-76.8 48.188235-94.870588-34.635294-94.870588-34.635294L263.529412 933.647059 180.705882 789.082353z m882.447059 143.058823L978.823529 882.447059s-18.070588 84.329412-94.870588 34.635294l-209.317647-120.470588 1.505882-132.517647 135.529412 82.823529c43.670588 25.6 94.870588-64.752941 51.2-88.847059l-138.541176-78.305882 117.458823-64.752941 203.294118 121.97647c79.811765 43.670588 18.070588 100.894118 18.070588 100.894118l84.329412 49.694118-84.329412 144.564705z"
                      fill="#E50012"
                      p-id="2272"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        <p className="order-info-tips text-danger">
          请按实际支付金额进行付款，否则充值不会到账。请勿重复支付。
        </p>
        <Copy
          name="卡号"
          noBtn
          value={cardNo}
          textStyle={{ display: 'block', margin: '1em 0', width: '100%' }}
        >
          <Button size="large" block>
            复制卡号
          </Button>
        </Copy>
        <Copy
          name="户名"
          value={bankAccount}
          noBtn
          textStyle={{ display: 'block', margin: '1em 0', width: '100%' }}
        >
          <Button size="large" block>
            复制户名
          </Button>
        </Copy>
      </div>
    </div>
  )
}

export default Bank

import axios from 'axios'
import { stringify } from 'qs'

const CancelToken = axios.CancelToken
const source = CancelToken.source()

const inst = axios.create({
  // baseURL: 'http://localhost:802/api',
  baseURL: '/api',
  timeout: 1000 * 60 * 2,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
  transformRequest: [
    function (data) {
      return stringify(data)
    },
  ],
})
inst.interceptors.request.use(
  function (config) {
    config.cancelToken = source.token
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

inst.interceptors.response.use(
  function (response) {
    const { data } = response
    return data
  },
  function (error) {
    console.log(error)
    return Promise.reject(error)
  }
)
export default inst
export const cancelRequest = source.cancel

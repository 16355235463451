import React, { Suspense, useEffect, useState } from 'react'
import { message as Message } from 'antd'
import Spin from '@/components/Spin'
import { fetchPayments } from '@/Service'

const Payments = React.lazy(() => import('./Payment'))

function ChoosePayment({ merchantId, to, from, price }) {
  const [fetchingPayment, setFetchingPayment] = useState(false)
  const [payments, setPayments] = useState(null)

  const checkPayment = async () => {
    setFetchingPayment(true)
    try {
      const { data, message } = await fetchPayments({ merchantId })
      if (data) {
        setFetchingPayment(false)
        setPayments(data || [])
      } else {
        Message.error(message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense
      fallback={
        <div className="container">
          <Spin />
        </div>
      }
    >
      <Payments
        price={price}
        loading={fetchingPayment}
        payments={payments || {}}
        to={to}
        from={from}
      />
    </Suspense>
  )
}

export default ChoosePayment

import React, { useContext, Suspense, useState } from 'react'
// import { parse, stringify } from 'qs'
import QrCode from '@/components/QrCode'
import Feedback from './Feedback'
import GoToStore from './GoToStore'
import Spin from '@/components/Spin'
import CountDown from './CountDown'
import { translate } from '@/language'
import OrderContext from '../../OrderContext'
import ToPhone from './ToPhone'
import NeedFeedback from './NeedFeedback'
import { isMobile } from '@/utils'
import AlipayH5 from './AlipayH5'

const BankCardInfo = React.lazy(() => import('./BankCardInfo'))
const VNPayInfo= React.lazy(() => import('./VNPayInfo'))
function OrderMainInfo() {
  const { orderInfo, icon, imagePathPrefix } = useContext(OrderContext)
  const {
    orderStatus: status,
    payType,payIcon,
    receiptQcode,
    receiptContent,
    payTimeOut
  } = orderInfo || {}
  const [isFinished, setIsFinished] = useState(false)

  const msg = () => {
    let text = translate().orderExpired
    if (String(status).includes('CANCEL')) {
      text = translate().orderCancel
    }
    if (status === 'ORDER_STATUS_COMPLETE') {
      text = translate().orderSuccess
    }
    return <span>{text}</span>
  }
  const getQrcode = () =>{
    if(receiptQcode&&receiptQcode!=''){
      return(<div style={{border:'dashed 2px #666',padding:'10px',borderRadius:'5px',marginBottom:'10px'}}>    
        <QrCode value={receiptQcode} icon={payIcon} />
      </div>)
    }
  }
  const getContent = () => {
    if (payType=='PAYTYPE_VN_BANK') {
      return (
        <Suspense fallback={<Spin />}>
          {getQrcode()}
          <BankCardInfo orderInfo={orderInfo} />
        </Suspense>
      )
    }
    else{
      return (
        <Suspense fallback={<Spin />}>
          {getQrcode()}
          <VNPayInfo orderInfo={orderInfo} />
        </Suspense>
      )
    }
  }

  const className = payType !== 'PAYTYPE_VN_BANK'  ? 'qr-code'  : 'bank-info'

  const isMsgNeedTiShown =
    String(status).includes('CANCEL') ||
    String(status).includes('COMPLETE') ||
    isFinished

  const isPaying = status === 'ORDER_STATUS_PAYING'

  return (
    <div>
      <div className={className}>
        <span>
          {(() => {
            if (!isPaying) {
              if (isMsgNeedTiShown) {
                return msg()
              }
              return (
                <CountDown
                  onTimeout={() => {
                    setIsFinished(true)
                  }}
                />
              )
            }
            if (isMsgNeedTiShown) {
              return msg()
            }
            return getContent()
          })()}
        </span>
      </div>
      {/* {getAppendContent()} */}
      {/* <GoToStore />
      <Feedback /> */}
    </div>
  )
}


export default OrderMainInfo

import React, { Suspense, useContext } from 'react'
import OrderContext from '../../OrderContext'
import Spin from '@/components/Spin'

// const BankCopyTips = React.lazy(() => import('./BankCopyTips'))
const AlipayH5Tips = React.lazy(() => import('./AlipayH5Tips'))
// const ALIPAY_BANK_TRANSFER = 'PAYTYPE_ALIPAY_BANK'

function OrderExtraInfo() {
  const { orderInfo } = useContext(OrderContext)
  const { payType } = orderInfo || {}
  return (
    <>
      {/* {String(payType).endsWith('_BANK') && payType !== ALIPAY_BANK_TRANSFER && (
        <div className="order-info-content-extra">
          <Suspense fallback={<Spin />}>{<BankCopyTips />}</Suspense>
        </div>
      )} */}
      {payType === 'PAYTYPE_ALIPAY_H5_SCAN' && (
        <div className="order-info-content-extra">
          <Suspense fallback={<Spin />}>{<AlipayH5Tips />}</Suspense>
        </div>
      )}
    </>
  )
}

export default OrderExtraInfo

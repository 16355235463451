/* eslint-disable import/first */
import React, { useEffect } from 'react'
import Bank from '@/pages/Bank'
// import Order from '@/pages/Order'
import NewIndex from '@/pages/Order'
// import Test from '@/pages/Test'

function App() {
  const { hash = '' } = window.location
  const [hashName = ''] = hash.split('?')
  // eslint-disable-next-line no-undef
  console.log('APP_NAME', APP_CONFIG)
  useEffect(() => {
    if (document) {
      document.title = '收银台'
    }
  }, [])

  switch (hashName.replace(/\/$/, '').toLowerCase()) {
    case '#/bank': {
      return <Bank />
    }
    // case '#/test': {
    //   return <Test />
    // }

    default:
      // return <Order />
      return <NewIndex />
  }
}

export default App

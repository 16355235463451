import React from 'react'
import { Button, Result } from 'antd'
import Spin from '@/components/Spin'
import { translate } from '@/language'
function OrderPlaceholder({
  visible,
  status = 'error',
  title = translate().orderFail,
}) {
  return visible ? (
    <Result
      status={status}
      title={title}
      style={{ margin: 'auto', color: '#fff' }}
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => {
            window.history.back()
          }}
        >
          {translate().return}
        </Button>
      }
    />
  ) : (
    <Spin />
  )
}

export default OrderPlaceholder

import React, { useContext } from 'react'
import OrderContext from '../../OrderContext'
import { translate } from '@/language'
function OrderTips() {
  const { orderInfo } = useContext(OrderContext)
  return (
    <>
      <div className="mt-1" style={{lineHeight:'25px'}}>
        {translate().orderTip1}{' '}
        <strong className="text-danger">
          {orderInfo.payablePrice}
        </strong>{' '}
        {translate().orderTip2}
      </div>
      <div className="text-danger text-bold size-3" style={{ marginTop: 12 }}>
        {orderInfo.merTips}
      </div>
    </>
  )
}

export default OrderTips

import React from 'react'
import { Spin } from 'antd'

function MySpin() {
  return (
    <div style={{ textAlign: 'center', margin: 'auto' }}>
      <Spin />
    </div>
  )
}

export default MySpin

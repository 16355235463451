import axios from '@/axios'

export const fetchOrderDetail = params => axios.post('/pay/find', params)

export const cancelOrder = params => axios.post('/pay/cancel', params)

export const orderFeedBack = params => axios.post('/pay/codeCancel', params)

export const fetchPayments = params => axios.post('/pay/payCode', params)

export const submitOrderFeedback = params => axios.post('/pay/setPwd', params)

import React from 'react'
import QrCodeReact from 'qrcode.react'

function QrCode(props) {
  const { value, children, dispatch, icon, ...restProps } = props
  const text = value || children
  return text ? (
    <QrCodeReact
      renderAs="svg"
      value={text}
      bgColor="#ffffff"
      fgColor="#000000"
      level="H"
      size={256}
      includeMargin={false}
      imageSettings={
        icon
          ? {
              src: icon,
              x: null,
              y: null,
              height: 48,
              width: 48,
              excavate: true,
            }
          : undefined
      }
      {...restProps}
    />
  ) : null
}

export default QrCode

import React, { useContext, useEffect, useRef } from 'react'
import Spin from '@/components/Spin'
import OrderContext from '../../OrderContext'
import { cancelRequest } from '@/axios'
import { Statistic } from 'antd'

function CountDown({ onTimeout }) {
  const {
    orderInfo: { timeout }
  } = useContext(OrderContext)

  const timeRef = useRef()

  useEffect(() => {
    if (!timeRef.current) {
      timeRef.current = timeout > 0 ? Date.now() + (timeout || 0) * 1000 : 0
    }
  }, [timeout])

  if (!timeRef.current) {
    return <Spin />
  }
  return (
    <Statistic.Countdown
      onFinish={() => {
        if (onTimeout) {
          onTimeout()
        }
        if (cancelRequest) {
          cancelRequest('timeout:abort')
        }
      }}
      prefix="Đơn hàng đang trong quá trình xử lý... "
      value={timeRef.current}
      format="s"
      suffix="s"
      style={{ display: 'inline' }}
      valueStyle={{ display: 'inline', fontSize: 14 }}
    />
  )
}

export default CountDown

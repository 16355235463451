import React, { useContext } from 'react'
import OrderContext from '../../OrderContext'
import { Button } from 'antd'

function GoToStore() {
  const { orderInfo } = useContext(OrderContext)

  const { receiptQcode, payType } = orderInfo || {}

  const gotoOtherStore = () => {
    const link = document.createElement('a')
    link.href = receiptQcode
    link.target = '_blank'
    link.click()
  }

  return (
    <div className="text-center mt-1">
      {receiptQcode && String(payType).endsWith('_PAY') && (
        <Button
          type="danger"
          onClick={gotoOtherStore}
          size="large"
          block
          style={{
            margin: '0 auto',
            display: 'block',
            backgroundColor: '#fc8848'
          }}
        >
          立即前往付款
        </Button>
      )}
    </div>
  )
}

export default GoToStore

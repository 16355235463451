import React from 'react'

function OrderPageContainer({ payName, extra, children, base, tips, action }) {
  return (
    <div className="order-info">
      <div className="order-info-title">{payName}</div>
      <div className="order-divider" />
      <div className="order-info-content">
        <div className="order-info-content-qr">{children}</div>
        <div className="order-divider-line" style={{ margin: '0 1em' }} />
        <div
          className="order-info-content-tips size-2"
          style={{ color: '#111', marginTop: 0 }}
        >
          <div className="order-info-content-tips-price text-center">
            {base}
          </div>
          {tips}
        </div>
        <div className="order-cancel">{action}</div>
        {extra}
      </div>
    </div>
  )
}

export default OrderPageContainer

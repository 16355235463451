import React from 'react'
import { Button, message } from 'antd'
import Copy from '@/components/Copy'

function AlipayH5({ orderInfo }) {
  const {
    receiptContent: pid,
    payablePrice: amount,
    orderCode,
    receiptName,
    receiptAccount,
  } = orderInfo

  const gotoAlipay = () => {
    // const { payUrl } = orderInfo
    const isWechat = (() => {
      const ua = navigator.userAgent.toLowerCase()
      // cSpell:disable-next-line
      return ua.includes('micromessenger')
    })()
    // const isAlipay = (() => {
    //   const ua = navigator.userAgent.toLowerCase()
    //   // cSpell:disable-next-line
    //   return ua.includes('alipayclient')
    // })()
    if (isWechat) {
      message.warn(
        <span>
          您在<span className="text-bold text-danger">微信</span>
          浏览器中，请在手机浏览器打开，点击右上角“...”，选择浏览器(safari)打开
        </span>
      )
      return
    }
    const data = JSON.stringify({
      s: 'money',
      u: pid,
      a: String(amount),
      m: orderCode,
    })
    const { origin, pathname } = window.location
    // cSpell:disable
    const redirect = `alipays://platformapi/startapp?appId=20000123&actionType=scan&biz_data=${data}`
    const url = `alipays://platformapi/startapp?appId=20000067&url=${
      // cSpell:enable
      encodeURIComponent(
        `${`${origin}${pathname}`.replace(
          /\/$/,
          ''
        )}/pay.html?url=${encodeURIComponent(btoa(redirect))}`
        // payUrl
      )
    }`
    console.log(url)
    window.location.href = url
  }

  const alipayAccount = [
    { value: receiptName, name: '姓名', text: receiptName },
    { value: receiptAccount, name: '支付宝', text: receiptAccount },
  ]

  return (
    <>
      <div style={{ marginTop: '0.5em' }}>
        {alipayAccount.map((item) => (
          <Copy key={item.name} {...item} btnProps={{ size: 'default' }} />
        ))}
      </div>
      <Button
        size="large"
        block
        type="primary"
        onClick={gotoAlipay}
        style={{ marginTop: '1em' }}
      >
        点击此处, 立即支付
      </Button>
    </>
  )
}

export default AlipayH5

import React from 'react'
import Copy from '@/components/Copy'

function ToPhone(props) {
  const { orderInfo = {} } = props
  const { receiptName, receiptAccount } = orderInfo || {}
  // console.log(orderInfo)
  return (
    <div style={{ maxWidth: 400, margin: '2em auto', width: '100%' }}>
      <div style={{ margin: '0 auto 12px' }}>
        {(() => {
          return [
            {
              value: receiptAccount,
              name: '手机号',
              text: receiptAccount,
            },
            {
              value: receiptName,
              name: '用户名',
              text: receiptName,
              noBtn: true,
            },
          ].map((item) => <Copy key={item.name} {...item} />)
        })()}
      </div>
    </div>
  )
}

export default ToPhone

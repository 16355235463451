import React from 'react'
import { Button, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { translate } from '@/language'
import './index.less'

function Copy(props) {
  const {
    value,
    name,
    icon,
    msg,
    text,
    children,
    textStyle = {},
    noBtn,
    btnProps = {},
    ...restProps
  } = props
  const displayText = text || children
  return (
    <div {...restProps} className="copy-item">
      {!noBtn && (
        <>
          <span className="copy-item-name">{name}: </span>
          <span className="copy-item-text">{displayText}</span>
        </>
      )}
      <CopyToClipboard
        onCopy={(text, result) => {
          if(result){
            message.success(translate().copySuccess)
          }
          else{
            message.error(translate().copyFail)
          }
        }}
        text={value || children}
      >
        {noBtn ? (
          <>
            <span className="copy-item-name">{name}: </span>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...textStyle,
              }}
            >
              {displayText}
            </span>
          </>
        ) : (
          <span className="copy-item-btn">
            <Button icon={icon} type="primary" {...btnProps}>
              {translate().copy}
            </Button>
          </span>
        )}
      </CopyToClipboard>
    </div>
  )
}

export default Copy
